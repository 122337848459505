<template>
  <div style="height: 100vh; overflow: scroll">
    <div>
      <div class="app-new-item"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading...">
        <div id="printDiv" v-show="printShow">
          <TestMethod
            :printModel="this.$data"
            style="height-max: 1000px"
          ></TestMethod>
        </div>
        <div class="new-item-head">
          Test Method Import {{ titleType }}
          <span
            style="position: absolute; left: 10px; cursor: pointer"
            @click="back">
            <i class="el-icon-arrow-left"></i>
          </span>
          <span
            style="
              position: absolute;
              right: 10px;
              cursor: pointer;
              font-weight: 400;
              font-size: 14px;
            "
          >
            <el-dropdown placement v-if="userInfo.username">
              <div>
                <i class="el-icon-user" style="margin-right: 10px"></i>
                {{ userInfo.username }}
                <i class="el-icon-arrow-down"></i>
                <!-- <span class="user-name">语言切换</span> -->
                <el-dropdown-menu class="user-dropdown" slot="dropdown">
                  <el-dropdown-item @click.native="logoutHandle"
                    >Logout</el-dropdown-item
                  >
                </el-dropdown-menu>
              </div>
            </el-dropdown>
          </span>
        </div>
      </div>
      <div
        style="
          background: white;
          margin-top: 40px;
          width: 90%;
          margin-left: 2.5%;
          padding: 40px;
        "
      >
        <center>
          <el-form
            label-position="right"
            label-width="310px"
            style="width: 900px; align: center"
            ref="formModel"
            :model="formModel"
          >
            <el-form-item label="方法编号">
              <el-input
                v-model="formModel.methodCode"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="方法名称">
              <el-input
                v-model="formModel.methodName"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="用途">
              <el-input
                v-model="formModel.methodUsage"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="参考法典">
              <el-input
                v-model="formModel.reference"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="操作程序">
              <el-input
                type="textarea"
                :rows="4"
                v-model="formModel.sop"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
            <el-form-item label="特别要求">
              <el-input
                v-model="formModel.specialRequirement"
                placeholder="Please Input"
              ></el-input>
            </el-form-item>
          </el-form>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="日期">
            <el-input
              v-model="formModel.createTime"
              placeholder="Date"
              style="width: 180px"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="提交人">
            <el-input
              v-model="formModel.createName"
              placeholder="Submitted by"
              style="width: 180px"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="审核人">
            <el-input
              v-model="formModel.verifiedBy"
              placeholder="Verified by"
              style="width: 180px"
              :disabled="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              v-model="formModel.note"
              placeholder="Note"
              style="width: 180px"
            ></el-input>
          </el-form-item>
        </el-form>
        </center>
        <div style="margin-top: 20px">
          <el-button v-if="userInfo.operateStatus" type="primary" @click="handleNew()">保存</el-button>
          <el-button
            v-if="userInfo.username === 'JamesChen' && methodId>0"
            type="warning"
            @click="handleConfirm"
            >审核</el-button>
          <el-button type="primary" icon="el-icon-printer" @click="printPage()">预览&打印</el-button>
          <el-button @click="back">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import printJS from "print-js";
import TestMethod from "../PrintProductTypeModule/Cn-TestMethod.vue";

export default {
  name: "new-item",
  mixins: [base],
  components: { TestMethod },
  data() {
    return {
      loading: false,
      titleType: "Add",
      formModel: {
        methodCode: "",
        methodName: "",
        methodUsage: "",
        reference: "",
        confirmed:2,
        language:1,
        sop:"",
        specialRequirement:"",
        note:"",
        verifiedBy:"",
        createName:"",
        createTime: base.methods.getLocalTime(-7),
        updateTime: base.methods.getLocalTime(-7),
        jsonValue:"",
      },
      methodId:0,
      printShow:false,
    };
  },
  computed: {
  },
  watch: {},
  methods: {
    back() {
      this.$router.push({ path: "cn-home"});
    },
    printPage() {
      this.printShow = true;
      const style = "@page {margin:0} @media print{}";
      var printFileName = ""; 
      if(this.formModel.methodName){
        printFileName ="(TestMethod) "+ this.formModel.methodName;
      }
      document.title = printFileName;
      setTimeout(() => {
        printJS({
          printable: "printDiv",
          type: "html",
          style: style,
          scanStyles: false,
        });
      }, 1.5);
      setTimeout(() => {
        this.printShow = false;
      }, 3);
    },
    getDetailById(params) {
      this.loading = true;
      this.$api.handleTestMethod.testMethodInfo(params).then((data) => {
        this.loading =false;
        this.formModel = data;
        console.log("this.formModel");
        console.log(this.formModel);
      });
    },
    async handleNew() {
      this.loading = true;
      var params = JSON.parse(JSON.stringify(this.formModel));
      console.info(params);
      if (params.id != null) {
        this.$api.handleTestMethod
          .testMethodUpdate(params)
          .then(() => {
            this.loading = false;
            var that = this
            this.$message({
              type: "success",
              message: "SuccessFul",
              duration: 1000,
              onClose: function () {
                that.$router.push({ path: '/cn-home' })
              }
            });
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      } else {
        this.$api.handleTestMethod
          .testMethodAdd(params)
          .then(() => {
            this.loading = false;
            var that = this
            this.$message({
              type: "success",
              message: "SuccessFul",
              duration: 1000,
              onClose: function () {
                that.$router.push({ path: '/cn-home' })
              }
            });
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      }
    },
    handleConfirm(){
      if (this.methodId != 0) {
        this.$confirm(
          "Confirm the audit, Continue?",
          "Tips",
          {
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
            distinguishCancelAndClose: true,
            type: "warning",
          }
        ).then(() => {
          this.$api.handleTestMethod
          .testMethodConfirm({'id':this.methodId,'note':this.formModel.note})
          .then(() => {
            this.loading = false;
            var that = this
            this.$message({
              type: "success",
              message: "SuccessFul",
              duration: 1000,
              onClose: function () {
                that.$router.push({ path: '/cn-home' })
              }
            });
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
        });
      }
    },
  },
  created() {
    let params = { ...this.$route.query };
    if (params.id) {
      this.methodId = params.id
      this.getDetailById(params.id);
      this.titleType = "Edit";
    } else {
      this.titleType = "Add";
      let specUserInfo = JSON.parse(localStorage.getItem('specUserInfo'))
      if(specUserInfo){
        this.formModel.createName = specUserInfo.username;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.app-new-item {
  .el-autocomplete ::v-deep .el-input__inner {
    width: 600px;
  }
  // width: 100%;
  // overflow: scroll;
  overflow-x: auto !important;
  overflow-y: auto;
  padding-top: 80px;
  .el-input,
  .el-select {
    width: 600px;
  }
  .area-input {
    height: 90px !important;
    line-height: 90px !important;
  }
  .new-item-head {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 99;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    font-weight: 600;
    color: #000;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
}
</style>